import React,{useState} from 'react'
import Database from "./DataBase.json"
import Modal from 'react-modal';
import ProductPopup from "./ProductPopup"

function FilterProduct(props) {
    const Rim_Size=[16, 18, 20, 24, 25, 28, 30]
    const Width_Size=[6.00,6.50,7.50,8.00,8.30,8.50,9.00,9.50,11.20,12.40,13.60,14.90,14.00,16.90,18.00,18.40]
    const Rim_Size_Select=Rim_Size.map((item,i)=><option key={i} value={item}>{item}</option>)
    const Width_Size_select=Width_Size.map((item,i)=><option key={i} value={item}>{item}</option>)
    const[loadMoreStatus,setLoadMoreStatus]=useState(6)
    const [selectedProduct,setSelectedProduct]=useState(0)
    console.log("props value",props)
    const [tyreFilters,setTyreFilters]=useState({
      Vehicle_Type:props.Filters_value.Vehicle_Type,
      search:"",
      Category:"",
      Tyre_width:props.Filters_value.Tyre_width,
      Rim_Size:props.Filters_value.Rim_size,
      load_more:6
    })
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const bindProductList=()=>
    {
      let selectProducts=[]
      let FilterProductssearch=Database.Products.filter((item)=>
      {
        let searchfilter=item.Code+" "+item.Size+" "+item.Description+" "+item.Vehicle_Type+" "+item.Used_In
        if(searchfilter.includes(tyreFilters.search)==true)
        {
          selectProducts.push(item)
        }
      })
      console.log("after search",selectProducts)
      if(tyreFilters.Vehicle_Type.length>0)
      {
        selectProducts=selectProducts.filter((item)=>(item.Vehicle_Type.toLowerCase()).includes(tyreFilters.Vehicle_Type.toLowerCase())==true)
      }
      if(tyreFilters.Category.length>0)
      {
        selectProducts=selectProducts.filter((item)=>item.Category.includes(tyreFilters.Category)==true)
      }
      if(tyreFilters.Tyre_width.length>0)
      {
        console.log("inside tyre width",String(selectProducts[0].Tyre_width))
        selectProducts=selectProducts.filter((item)=>String(item.Tyre_width)==tyreFilters.Tyre_width)
      }
      if(tyreFilters.Rim_Size.length>0)
        {
          selectProducts=selectProducts.filter((item)=>String(item.Rim_Size)==tyreFilters.Rim_Size)
        }
      console.log("selected product",selectProducts)
      let JSXProducts=selectProducts.map((item,i)=>{
        if(i==0 ||i<3)
        {
          return <div key={i} onClick={() =>{
            setSelectedProduct(item.Code);
             setModalIsOpen(true)}} className="prod-cards">
          <img src="./icons/ourProd-tyre-rect.svg" alt="img" className="img-top" />
          <h5>{item.Code} - {item.Size}</h5>
          <img src="./icons/ourProd-tyre-img.svg" alt="img" className="img-down" />
        </div>
        }
        else if(i%3==0)
        {
          return <div key={i} onClick={() =>{  setSelectedProduct(item.Code); 
          setModalIsOpen(true)}} style={{marginTop:'3.451vw'}} className="prod-cards">
          <img src="./icons/ourProd-tyre-rect.svg" alt="img" className="img-top" />
          <h5>{item.Code} - {item.Size}</h5>
          <img src="./icons/ourProd-tyre-img.svg" alt="img" className="img-down" />
        </div>
        }
        else{
          return <div key={i} onClick={() =>{  setSelectedProduct(item.Code); setModalIsOpen(true)}} style={{marginTop:'3.541vw',marginLeft:'5.323vw'}} className="prod-cards">
          <img src="./icons/ourProd-tyre-rect.svg" alt="img" className="img-top" />
          <h5>{item.Code} - {item.Size}</h5>
          <img src="./icons/ourProd-tyre-img.svg" alt="img" className="img-down" />
        </div>
        }
            
    }
    )
    return JSXProducts.slice(0,tyreFilters.load_more)
    }
    const handleVehicleType=(event)=>
    {
      let eventValue=event.target.innerText;
      if(eventValue=="All tyres")
      {
        setTyreFilters({...tyreFilters,Vehicle_Type:""})
      }else if(eventValue=="Agricultural Vehicle"){
        setTyreFilters({...tyreFilters,Vehicle_Type:"Agricultural"})
      }else if(eventValue=="Industrial Vehicle"){
        setTyreFilters({...tyreFilters,Vehicle_Type:"Industrial"})
      }else if(eventValue=="OTR"){
        setTyreFilters({...tyreFilters,Vehicle_Type:"OTR"})
      }
    }
    const handleLoadMorebutton=()=>
    {
      setTyreFilters({...tyreFilters,load_more:tyreFilters.load_more+3})
    }
  return (
    <div>
        <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={{content:{
          width:'74.425vw',
          overflow:'hidden',
          marginLeft:'7.5vw',
        }}}
      >
        <ProductPopup productID={selectedProduct} modalOpen={setModalIsOpen} />
      </Modal>
        <div className="prod">
        <h2>Our Products</h2>
        <ul className="prod-cont">
          <li><button  className={tyreFilters.Vehicle_Type==""?'prod_selected_vehcile_type':'prod_vehcile_type'}  onClick={handleVehicleType}  type="button">All tyres</button></li>
          <li><button className={tyreFilters.Vehicle_Type=="Agricultural"?'prod_selected_vehcile_type':'prod_vehcile_type'}  onClick={handleVehicleType} style={{paddingLeft:'35px'}} type="button">Agricultural Vehicle</button></li>
          <li><button className={tyreFilters.Vehicle_Type=="Industrial"?'prod_selected_vehcile_type':'prod_vehcile_type'}  onClick={handleVehicleType}  style={{paddingLeft:'35px'}} type="button">Industrial Vehicle</button></li>
          <li><button className={tyreFilters.Vehicle_Type=="OTR"?'prod_selected_vehcile_type':'prod_vehcile_type'}  onClick={handleVehicleType} type="button">OTR</button></li>
          <li>
            <input type="text" onChange={(event)=>
              {
                setTyreFilters({...tyreFilters,search:event.target.value})
              }
            } placeholder="Search tyre" />
            <i className="fas fa-search"></i>
          </li>
        </ul>
      </div>
    <div className="prod-container">
      <div className="prod-side-nav">
    <h4 className="fi">Filter By Tyre</h4>
    <ul>
      <li  onClick={()=>
        {
          setTyreFilters({...tyreFilters,Category:'Front tyre'})
        }
      } ><a className={tyreFilters.Category=="Front tyre"?'prod-side-nav-selected-filter':null} href="#">Front tyre</a></li>
      <li  onClick={() =>
        {
          setTyreFilters({...tyreFilters, Category: 'Rear tyre'})
        }
      }><a className={tyreFilters.Category=="Rear tyre"?'prod-side-nav-selected-filter':null} href="#">Rear tyre</a></li>
      {/* <li onClick={() =>
        {
          setTyreFilters({...tyreFilters, Category: 'Trailer tyre'})
        }
      }><a className={tyreFilters.Category=="Trailer tyre"?'prod-side-nav-selected-filter':null} href="#">Trailer tyre</a></li>
      <li onClick={() =>
        {
          setTyreFilters({...tyreFilters, Category: 'ADV tyre'})
        }
      }><a className={tyreFilters.Category=="ADV tyre"?'prod-side-nav-selected-filter':null} href="#">ADV tyre</a></li>
      <li onClick={() =>
        {
          setTyreFilters({...tyreFilters, Category: 'Tiller tyre'})
        }
      }><a className={tyreFilters.Category=="Tiller tyre"?'prod-side-nav-selected-filter':null} href="#">Tiller tyre</a></li>
      <li onClick={() =>
        {
          setTyreFilters({...tyreFilters, Category: 'Implement tyre'})
        }
      }><a className={tyreFilters.Category=="Implement tyre"?'prod-side-nav-selected-filter':null} href="#">Implement tyre</a></li> */}
    </ul>
    <h4 className="sec">Filter By Size</h4>
    <label htmlFor="rim">Rim size in inches</label>
    <select onChange={(event)=>
      {
        setTyreFilters({...tyreFilters, Rim_Size:''+event.target.value})
      }
    } name="rim" id="rim">
    <option value="" disabled selected>Select Rim Size</option>
      {Rim_Size_Select}
    </select>

    <label htmlFor="wi">Width in inches</label>
    <select onChange={(event)=>
      {
        setTyreFilters({...tyreFilters, Tyre_width:''+event.target.value})
      }
    } name="wi" id="wi">
      <option value="" disabled selected>Select width</option>
      {Width_Size_select}
    </select>
    <div className='clear_filter_div'>
    <i className="fa-solid fa-x"></i>
    <p className="clear_filter" onClick={() => setTyreFilters({ ...tyreFilters, Rim_Size: "", Tyre_width: "", Vehicle_Type: "", search: "", Category: "" })}>Clear Filter</p>
    </div>
  </div>
  <div className="prod-content">
    {bindProductList().length!=0?bindProductList():<img src='https://static.vecteezy.com/system/resources/previews/010/856/652/large_2x/no-result-data-document-or-file-not-found-concept-illustration-flat-design-eps10-modern-graphic-element-for-landing-page-empty-state-ui-infographic-icon-etc-vector.jpg' style={{width:'40vw',marginLeft:'20vw'}}/>}
      </div>
    </div>
      <div className="prod-lo-btn">
       {bindProductList().length<6?null:<button onClick={handleLoadMorebutton} type="button">Load more</button>}
      </div>
    </div>
  )
}

export default FilterProduct