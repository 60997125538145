import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
const NavBarMobile = () => {
    const[dropdown,setDropdown]=useState(true)
    const handleHamburger=()=>
    {
    if(dropdown==true)
    {
    setDropdown(false)
    }else{
    setDropdown(true)
    }
    }
    const navigation=useNavigate()
    const handleNavbar=(event)=>
        {
            let value=event.target.textContent
            if( value=="Home"){
                navigation('/')
            }
            else if( value=="Our Products"){
                navigation("/Products")
            }
            else if (value=="Our Services"){
                navigation("/Our-service")
    
            }else if (value=="About Us"){
                navigation("/About-us")
    
            }else if (value=="Contact Us"){
                navigation("/Contact-us")
            }
        }
        
  return (
    <div className="nav-bar-mobile-screen">
      <div className="nav_bar_mobile">
        <ul style={{ display: 'flex' }}>
          <li onClick={()=>
              navigation('/')
          } textContent="Home">
            <img
              className="Phone_Home_icon"
              src="./icons/phone_Navbar_Home_icon.svg"
              alt="Home Icon"
            />
          </li>
          <li onClick={handleHamburger}>
            <img
              className="Phone_hamburger_icon"
              src="./icons/Phone_navbar_hamburgericon.svg"
              alt="Menu Icon"
            />
          </li>
        </ul>
        <div className={dropdown==false?"nav_mobile_lists":"nav_mobile_lists_diaplay"}>
          <ul>
            <li onClick={handleNavbar}>Our Services</li>
            <li onClick={handleNavbar}>Our Products</li>
            <li onClick={handleNavbar}>Contact Us</li>
            <li onClick={handleNavbar}>About Us</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavBarMobile;
