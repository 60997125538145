import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyCBoHhnnNs22mu3JUx9voksUf88_eY892w",
  authDomain: "srstyresfinal.firebaseapp.com",
  projectId: "srstyresfinal",
  storageBucket: "srstyresfinal.appspot.com",
  messagingSenderId: "174996070454",
  appId: "1:174996070454:web:a51676aafb39553e2df897"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export { storage };
