import React from 'react'
import { useNavigate } from 'react-router-dom'

function PhoneFooter() {
  const navigation=useNavigate()
  const handleFooter=(event)=>
      {
          let value=event.target.textContent
          if( value=="Home"){
              navigation('/')
          }
          else if( value=="Our Products"){
              navigation("/Products")
          }
          else if (value=="Our Services"){
              navigation("/Our-service")
  
          }else if (value=="About Us"){
              navigation("/About-us")
  
          }else if (value=="Contact Us" ||value=="Sell your used Tyre" || value=="Retreading"){
              navigation("/Contact-us")
          }
      }
  return (
    <footer className="foot">
        <div className="footer-left">
          <img src="../icons/srs_logo footer.svg" alt="SRS Logo" />
          <p>Your trusted partner in tyre retreading</p>
        </div>
        <div className="footer-right">
          <ul className="first">
            <h4>Explore</h4>
            <li onClick={handleFooter}><a>Home</a></li>
            <li onClick={handleFooter}><a>About Us</a></li>
            <li onClick={handleFooter}><a>Contact Us</a></li>
          </ul>
          <ul className="third">
            <h4>Reach Us</h4>
            <li><a href="tel:+919443712616">+91 9443712616</a></li>
            <li><a href="mailto:business@srstyres.com">business@srstyres.com</a></li>
            <li><a href="tel:+918072861608">+91 8072861608</a></li>
            <li><a href="mailto:ragavdurai1997@gmail.com">ragavdurai1997@gmail.com</a></li>
            <li style={{ fontSize: '1vw', fontStyle: 'italic', fontFamily: "'Inter', sans-serif" }}>
              <p>No.31, Mambalapattu Rd, Vandimedu, Viluppuram, Tamil Nadu 605602</p>
            </li>
          </ul>
          <ul className="second">
            <h4>Our Services</h4>
            <li onClick={handleFooter}><a>Our Products</a></li>
            <li onClick={handleFooter}><a >Retreading</a></li>
            <li onClick={handleFooter}><a >Sell your used Tyres</a></li>
          </ul>
        </div>
      </footer>
  )
}

export default PhoneFooter