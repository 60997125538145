import React,{useRef} from 'react'
import NavBarMobile from './NavBarMobile';
import Navbar from './navbar';
import PhoneFooter from './PhoneFooter';
import { useNavigate } from 'react-router-dom'

function PhoneOurservices() {
  const retreadingsectionRef = useRef(null);
  const navigation=useNavigate()
  const TyressectionRef = useRef(null);
  const handleRetreadingClick=()=>
  {
      if (retreadingsectionRef.current) {
          retreadingsectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
  }
  const handleOurTyreClick=()=>
      {
          if (TyressectionRef.current) {
              TyressectionRef.current.scrollIntoView({ behavior: 'smooth' });
            }
      }
  return (
    <div className="our-services-mobile-screen">
        <NavBarMobile/>
    <div className="our_services_banner">
      <p className="our_services_banner_heading">Our Services</p>
      <div style={{ display: 'flex' }}>
        <p onClick={handleRetreadingClick}  className="our_services_banner_button">
          Retreading
        </p>
        <p onClick={handleOurTyreClick} className="our_services_banner_button_2">
        Our Tyres
        </p>
      </div>
    </div>
    <div>
      <p className="what_is_retreading"  ref={retreadingsectionRef}>What is Retreading?</p>
      <p className="what_is_retreading_content">
        Retreading is a method of refurbishing a worn tyre by removing the old tread and applying a new layer of rubber. This process allows the tyre to be reused, extending its lifespan and providing a cost-effective solution compared to purchasing a new tyre.
      </p>
    </div>
    <div className="the_process_down">
      <p className="the_process">The process</p>
      <p className="the_process_content">
        At SRS Tyres, we take pride in our careful retreading process, ensuring that each tyre meets the highest standards of quality and safety.
      </p>
      <img src="./icons/how_do_we.svg" alt="How do we process" style={{ width: '100%' }} className="how_do_we" />
    </div>
    <div className="retread_any_tyre">
      <p>
        At SRS Tyres, we blend advanced technology with skilled craftsmanship to deliver retreaded tyres that are as good as new.
      </p>
      <button>Retread my tyre</button>
    </div>
    <div>
      <img src="./icons/line.svg" alt="Line" className="line_class" />
    </div>
    <p className="do_you_know">Do you know this?</p>
    <div className="the_lugs_difference">
      <img src="./icons/the_lugs_difference.svg" alt="The lugs difference" />
    </div>
    <p className="below_lugs_difference">
      At SRS Tyres, we've recognized the importance of proper lug design in enhancing tyre performance. By providing our customers with tailored lug patterns, we've made a significant difference, offering them better traction and durability. This focus on quality has benefited our customers, helping them achieve superior results in challenging conditions.
    </p>
    <img className="line2" src="./icons/line2.svg" alt="Line 2" />
    <p className="our_tyre" ref={TyressectionRef}>Our Tyres</p>
    <p className="out_tyre_content">
      At SRS Tyres, we specialize in offering high-quality retreaded tyres that match the performance of new tyres. We purchase worn-out tyres from our customers, expertly retread them, and sell them in our exclusive store. Our customers trust and appreciate the quality and reliability of our products. By focusing solely on retreaded tyres, we not only provide a cost-effective solution but also contribute to a greener environment.
    </p>
    <div className="the_greener_way">
      <p className="the_greener_way_heading">The greener way</p>
      <p className="the_greener_way_heading_content">
        As a tyre retreading company, we are committed to providing an environmentally friendly solution that reduces waste and conserves resources. We appreciate their efforts in helping us protect the planet and promote responsible consumption.
      </p>
      <img className="the_greener_way_line3" src="./icons/line3.svg" alt="Line 3" />
      <div className="contribute_and_click_here">
        <p>Contribute to nature, sell us your used tyres</p>
        <button onClick={()=>
                    {
                        navigation("/Contact-us")
                    }} >Click here</button>
      </div>
      <img className="lin_4_contribute" src="./icons/line3.svg" alt="Line 4" />
    </div>
    <PhoneFooter/>
  </div>
  )
}

export default PhoneOurservices