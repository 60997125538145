import React from 'react'
import { useNavigate } from 'react-router-dom'

function Navbar() {
    const navigation=useNavigate()
    const handleNavbar=(event)=>
        {
            let value=event.target.textContent
            if( value=="Home"){
                navigation('/')
            }
            else if( value=="Our Products"){
                navigation("/Products")
            }
            else if (value=="Our Services"){
                navigation("/Our-service")
    
            }else if (value=="About Us"){
                navigation("/About-us")
    
            }else if (value=="Contact Us"){
                navigation("/Contact-us")
            }
        }
  return (
    <div className="nav_bar">
            <img src="./icons/srs_logo.svg" style={{ width: '33%', float: 'left' }} />
            <div className="nav-elements">
                <ul>
                    <li onClick={handleNavbar} style={{ marginLeft: '15%' }}>Home</li>
                    <li  onClick={handleNavbar} style={{ marginLeft: '3%' }}>Our Products</li>
                    <li  onClick={handleNavbar} style={{ marginLeft: '3%' }}>Our Services</li>
                    <li  onClick={handleNavbar} style={{ marginLeft: '3%' }}>About Us</li>
                    <li  onClick={handleNavbar} style={{ marginLeft: '3%' }}>Contact Us</li>
                </ul>
            </div>
        </div>
  )
}

export default Navbar