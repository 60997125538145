import React from 'react'
import { useNavigate } from 'react-router-dom'

function Footer() {
    const navigation=useNavigate()
    const handleFooter=(event)=>
        {
            let value=event.target.textContent
            if( value=="Home"){
                navigation('/')
            }
            else if( value=="Our Products"){
                navigation("/Products")
            }
            else if (value=="Our Services"){
                navigation("/Our-service")
    
            }else if (value=="About Us"){
                navigation("/About-us")
    
            }else if (value=="Contact Us" ||value=="Sell your used Tyre" || value=="Retreading"){
                navigation("/Contact-us")
            }
        }
  return (
    <div>
         <footer className="foot">
    <div className="footer-left">
        <img src='./icons/srs_logo footer.svg' alt="SRS Logo" />
        <h6>Your trusted partner in tyre retreading</h6>
    </div>
    <div className="footer-right">
        <ul className="first">
            <h4>Explore</h4>
            <li onClick={handleFooter}>Home</li>
            <li onClick={handleFooter}>About Us</li>
            <li onClick={handleFooter}>Contact Us</li>
        </ul>
        <ul className="second">
            <h4>Our Services</h4>
            <li onClick={handleFooter}>Our Products</li>
            <li onClick={handleFooter}>Retreading</li>
            <li onClick={handleFooter}>Sell your used Tyres</li>
        </ul>
        <ul className="third">
            <h4>Reach Us</h4>
            <li><a href="tel:+919443712616">+91 9443712616</a></li>
            <li><a href="mailto:business@srstyres.com">business@srstyres.com</a></li>
            <li><a href="tel:+918072861608">+91 8072861608</a></li>
            <li><a href="mailto:ragavdurai1997@gmail.com">ragavdurai1997@gmail.com</a></li>
            <li style={{ fontSize: '1vw', fontStyle: 'italic', fontFamily: "'Inter', sans-serif" }}>
                No.31, Mambalapattu Rd, Vandimedu, Viluppuram, Tamil Nadu 605602
            </li>
        </ul>
    </div>
</footer>
</div>
  )
}

export default Footer