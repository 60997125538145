import React from 'react';
import { useState,useEffect } from 'react';
import Database from "./DataBase.json"
import { Carousel } from 'react-responsive-carousel';

const ProductPopup = (props) => {
    const [activeAccordian,setactiveAccordian]=useState(null)
    const [currentImage,setcurrentImage]=useState({
        mainImage:"../icons/ourProd-tyre-img.svg",
        subImage1:"../icons/Products_tyre_image2.jpeg",
        subImage2:"../icons/Products_tyre_image3.jpeg"
    })
    const handleactiveAccordian=(value)=>
    {
        console.log("activeAccordian",activeAccordian)
        console.log("value",value)
        if (value==activeAccordian)
            {
                console.log("came inside")
                setactiveAccordian(null)
            }
            else if(value=="Details" )
        {
            setactiveAccordian("Details")
        }
        else if(value=="More")
        {
            setactiveAccordian("More")
        }else if (value=="Features")
        {
            setactiveAccordian("Features")
        }
    }
    const [ProductDetails,setProductDetails]=useState({
        title:"",
        description:"",
        Vehicle_Type:"",
        size:"",
        usedIn:"",
        Rating:"",
        Load_Capacity:"",
        featuresJSX:"",
        MoreJSX:""

    })
    useEffect(()=>
    {
        for(let i=0;i<Database.Products.length;i++)
            {
                if(Database.Products[i].Code==props.productID)
                {
                    setProductDetails({
                        title:Database.Products[i].Size+" - "+Database.Products[i].Vehicle_Type+" Tyre",
                        description:Database.Products[i].Description,
                        Vehicle_Type:Database.Products[i].Vehicle_Type,
                        size:Database.Products[i].Size,
                        usedIn:Database.Products[i].Used_In,
                        Rating:Database.Products[i].Rating,
                        Load_Capacity:Database.Products[i].Load_Capacity,
                        featuresJSX:Database.Products[i].Features.slice(0,4).map((item,i)=><li style={{listStyleType:'circle !important'}} key={i}>{item}</li>),
                        MoreJSX:Database.Products[i].More.map((item,i)=><li key={i}>{item}</li>)
                    })
                }
            }
    },[])
    const handleClickcall = () => {
        window.location.href = 'tel:+1234567890'; // Replace with your phone number
      };

    return (
        <div className="prod-popup">
            <div className="prod-left">
            <div>
            <Carousel  
        interval={3000} 
        showThumbs={false} 
        showArrows={true} 
        showStatus={false}
        >
          <img src={currentImage.mainImage} alt="img" className="img-big" />
          <img src={currentImage.subImage1} alt="img" className="img-big" />
          <img src={currentImage.subImage2} alt="img" className="img-big" />
        </Carousel>
            </div>
                
                <div className="prod-left-down">
                    <img  src={currentImage.subImage1} onClick={()=>
                        {
                            setcurrentImage({
                                ...currentImage,
                                mainImage:currentImage.subImage1,
                                subImage1:currentImage.mainImage
                            })
                        }
                    } alt="" className="img-small" />
                    <img src={currentImage.subImage2} onClick={()=>
                        {
                            setcurrentImage({
                                ...currentImage,
                                mainImage:currentImage.subImage2,
                                subImage2:currentImage.mainImage
                            })
                        }} alt="" className="img-small" />
                    <p>*Images shown are only for representation. Products may differ in real</p>
                </div>
            </div>
            <div className="prod-right">
                <p onClick={()=>
                    {
                        props.modalOpen(false)
                    }
                } style={{position:'absolute',marginLeft:'725px',marginTop:'5px',color:'black',cursor:'pointer'}}>X</p>
                <p>{ProductDetails.title}</p>
                <h6>{ProductDetails.description}</h6>
                <div className="prod-spacing">
                    <div onClick={()=>
                        {
                            handleactiveAccordian("Details")
                        }
                    } className={activeAccordian=="Details"?"prod-accordion active":"prod-accordion"}>
                        <div className="label">Details</div>
                        <div className="cont">
                            <ul>
                                <li><span>Size:</span>{ProductDetails.size}</li>
                                <li><span>Vehicle Type:</span>{ProductDetails.Vehicle_Type}</li>
                                <li><span>Used In:</span>{ProductDetails.usedIn}</li>
                                <li><span>Load Capacity:</span>{ProductDetails.Load_Capacity}</li>
                                <li><span>Rating:</span>{ProductDetails.Rating}</li>
                            </ul>
                        </div>
                    </div>

                    <div onClick={()=>
                        {
                            handleactiveAccordian("Features")
                        }
                    } className={activeAccordian=="Features"?"prod-accordion active":"prod-accordion"}>
                        <div className="label">Features</div>
                        <div className="cont" style={activeAccordian=="Features"?{height:'5vw'}:{}}>
                            <ul>
                            {ProductDetails.featuresJSX}
                            </ul>
                        </div>
                    </div>

                    <div onClick={()=>
                        {
                            handleactiveAccordian("More")
                        }
                    } className={activeAccordian=="More"?"prod-accordion active":"prod-accordion"}>
                        <div className="label">More</div>
                        <div className="cont">
                            <ul>
                            {ProductDetails.MoreJSX}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="prod-acc-btn">
                    <button onClick={handleClickcall} type="button">Call now</button>
                </div>
                <p className="p-2" style={{ color: '#716E6E', fontSize: '0.521vw', marginTop: '13.35vw' }}>*Terms and conditions apply*</p>
            </div>
        </div>
    );
};

export default ProductPopup;
