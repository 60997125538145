import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useNavigate } from 'react-router-dom';
import Footer from "./Footer"
import Database from "./DataBase.json"
import { useState } from 'react';
import NavBarMobile from './NavBarMobile';
import PhoneFooter from './PhoneFooter';

function Home() {
    const navigation=useNavigate()
    const[HomeFilter,setHomeFilter]=useState({
      Vehicle_Type:"",
      Rim_size:"",
      Tyre_width:""
    })
    const handleNavbar=(event)=>
    {
        let value=event.target.textContent
        if( value=="Home"){
            navigation('/')
        }
        else if( value=="Our Products"){
            navigation("/Products")
        }
        else if (value=="Our Services"){
            navigation("/Our-service")

        }else if (value=="About Us"){
            navigation("/About-us")

        }else if (value=="Contact Us"){
            navigation("/Contact-us")

        }
    }
    const bindchoosebyvechile=()=>{
      let vechile_types=[]
      for(let i=0;i<Database.Products.length;i++)
      {
        if(vechile_types.includes(Database.Products[i].Vehicle_Type)==false)
        vechile_types.push(Database.Products[i].Vehicle_Type)
      }
      return vechile_types.map((item,i)=><option value={item} key={i}>{item}</option>)
    }
    const handleSearchFilter=(event)=>
    {
      let EventName=event.target.name
      let EventValue=event.target.value
      if(EventName=="vechile"){
        setHomeFilter({
          ...HomeFilter,
          Vehicle_Type:EventValue
        })
      }else{
        setHomeFilter({
          ...HomeFilter,
          Tyre_width:EventValue.split("-")[0],
          Rim_size:EventValue.split("-")[1]
        })

      }
    }
    console.log(HomeFilter)
    const bindTyreSize=()=>
    {
      let tyre_size=[]
      for(let i=0;i<Database.Products.length;i++)
      {
        if(HomeFilter.Vehicle_Type==Database.Products[i].Vehicle_Type)
        {
          let tyre_size_filter=""+Database.Products[i].Tyre_width+"-"+Database.Products[i].Rim_Size
          if(tyre_size.includes(tyre_size_filter)==false)
          {
            tyre_size.push(tyre_size_filter)
          }
        }
      }
      return tyre_size.map((item,i)=><option value={item} key={i}>{item}</option>)
    }
  return (
    <div>
      <div className='laptop_home'>
        <div className="home_nav_bar">
        <img src="./icons/srs_logo.svg" style={{ width: '33%', float: 'left' }} alt="SRS Logo" />
        <div className="home_nav-elements">
          <ul>
            <li style={{ marginLeft: '15%' }} onClick={handleNavbar}>Home</li>
            <li style={{ marginLeft: '3%' }} onClick={handleNavbar}>Our Products</li>
            <li style={{ marginLeft: '3%' }} onClick={handleNavbar}>Our Services</li>
            <li style={{ marginLeft: '3%' }} onClick={handleNavbar}>About Us</li>
            <li style={{ marginLeft: '3%' }} onClick={handleNavbar}>Contact Us</li>
          </ul>
        </div>
      </div>
        <Carousel  
        autoPlay
        interval={3000} 
        infiniteLoop={true}
        showThumbs={false} 
        showArrows={false} 
        showStatus={false}  
        showIndicators={true}
        stopOnHover={false}>
           <div className="home_banner_2">
        <p className="home_banner_2_content">
            <span style={{ color: '#1B0C75' }}>We specialize</span> in retreading and selling high-quality retreaded tires, providing exceptional service and reliable results. Trust us for durable, cost-effective tire solutions.
        </p>
        <div className="home_banner_2_button">
            <button onClick={()=>
                {
                    navigation("/Our-service")
                }
            }>Retreading</button>
            <button onClick={()=>
                {
                    navigation("/Contact-us")
                }
            } className="home_banner_2_button_2">Contact Us</button>
        </div>
    </div>
        <div className="home_banner_1">
            <p className="home_banner_1_heading">Experience the Difference with Our Tyres</p>
            <button className="home_banner_1_button" onClick={()=>{
                navigation("/Our-service")
            }}>Our Services</button>
       </div> 
    <div className="home_banner_3">
      <div className="home_banner_3_card">
        <p className="home_banner_3_card_content">
          Founded in 1997, our company has been dedicated to providing exceptional tire retreading services and high-quality retreaded tyres.
          With over two decades of experience, we have established ourselves as a trusted partner in the industry, known for our commitment to quality, reliability, and customer satisfaction. Our expertise ensures that every tire we retread meets the highest standards of durability and performance.
        </p>
        <button onClick={()=>
            {
                navigation("/About-us")
            }
        } className="home_banner_3_card_button">About Us</button>
      </div>
    </div>
        </Carousel>
    <div className="home_choose">
      <div className="home_choose_input_1">
        <p>Choose by Vehicle</p>
        <select name='vechile' onChange={handleSearchFilter}>
        <option value="" disabled selected>Select Vehicle</option>
          {bindchoosebyvechile()}
        </select>
      </div>
      <div className="home_choose_input_2">
        <p>Choose by Size</p>
        <select name='Size' onChange={handleSearchFilter}>
        <option value="" disabled selected>Select Size</option>
          {bindTyreSize()}
        </select>
      </div>
      <button className="home_choose_button" onClick={()=>
        {
          navigation("/Products",{ state: HomeFilter })
        }
      }>Show Tyres</button>
    </div> 
    <div className="home_srs_since">
        <p className="home_srs_since_content">
          <span>Since 1997</span>, our industry-leading tire retread has been setting a standard against which all others are measured for both quality and reliability. With state-of-the-art equipment, we assure long-lasting durability and excellent performance with advanced techniques in every retreaded tire. Our experienced team carefully inspects and processes each tire to ensure safety according to world-class standards. We offer cost-effective, eco-friendly solutions that assure a secure and smooth journey.
        </p>
        <img src="./icons/home_srs_tyres_embelam.svg" alt="Emblem" />
      </div>
      
      <div className="home_customer_review">
        <p className="home_customer_review_heading">Our Customer reviews</p>
        <div className="home_customer_review_1">
          <img src="./icons/review1.png" alt="Review 1" />
          <img style={{ marginLeft: '9.15vw' }} src="./icons/review2.png" alt="Review 2" />
        </div>
        <div className="home_customer_review_2">
          <img src="./icons/review3.png" alt="Review 3" />
          <img style={{ marginLeft: '9.15vw' }} src="./icons/review4.png" alt="Review 4" />
        </div>
      </div>

      <div className="get_quotation">
        <p className="get_quotation_heading">‘Turn your worn-out tire into new’</p>
        <div className="get_quotation_button" onClick={()=>
            {
                navigation("/Contact-us")
            }
        }>
          <p>Get Quotation</p>
        </div>
      </div>
      <Footer/>
    </div>
    <div className='Phone_home'>
      <NavBarMobile/>
    <Carousel infiniteLoop 
        autoPlay
        interval={3000} 
        showThumbs={false} 
        showArrows={false} 
        showStatus={false}  
        showIndicators={true}
        stopOnHover={false}>
        <div class="Phone_Home_banner_1">
                 <p class="Phone_we_specialize">We specialize</p>
                 <p class="Phone_we_specialize_content">in retreading and selling high-quality retreaded tires, providing exceptional service and reliable results. Trust us for durable, cost-effective tire solutions.</p>
                 <button class="Phone_we_specialize_button" onClick={()=>
                {
                    navigation("/Our-service")
                }}>Retreading</button>
                 <button onClick={()=>
                  {
                      navigation("/Contact-us")
                  }
              } class="Phone_we_specialize_button">Contact Us</button>
        </div>
        <div class="Phone_Home_banner_2">
                <p class="Phone_Experience">Experience the Difference with
                    Our Tyres</p>
                <button class="Phone_our_services_button" onClick={()=>
                {
                    navigation("/Our-service")
                }}>Our Services</button>
           </div>
    <div class="Phone_Home_banner_3">
            <div class="Phone_Home_banner_3_glass">
                <p class="Phone_Home_banner_3_glass_text">Founded in 1997, our company has been dedicated to providing exceptional tire retreading services and high-quality retreaded tyres. With over two decades of experience, we have established ourselves as a trusted partner in the industry, known for our commitment to quality, reliability, and customer satisfaction. Our expertise ensures that every tire we retread meets the highest standards of durability and performance.</p>
                <button class="Phone_Home_banner_3_glass_button" onClick={()=>
            {
                navigation("/About-us")
            }
        }>About Us</button>
            </div>
           </div>
           </Carousel>
           <div className="Phone_Home_choose">
      <div className="Phone_Home_choose_vehicle">
        <label>Choose by Vehicle</label>
        <select name='vechile' onChange={handleSearchFilter}>
        <option value="" disabled selected>Select Vehicle</option>
          {bindchoosebyvechile()}
        </select>
      </div>
      <div className="Phone_Home_choose_vehicle Phone_Home_choose_size">
        <label>Choose by Size</label>
        <select name='Size' onChange={handleSearchFilter}>
        <option value="" disabled selected>Select Size</option>
          {bindTyreSize()}
        </select>
      </div>
      <button onClick={()=>
        {
          navigation("/Products",{ state: HomeFilter })
        }}>Show Tyres</button>
    </div>
    <div className="Phone_home_since">
      <p className="Phone_home_since_content">
        <span>Since 1997</span>, our industry-leading tire retread has been setting a standard against which all others are measured for both quality and reliability.
      </p>
    </div>
    <div>
      <img className="Phone_Home_srs_Emblem" src="./icons/Phone_Home_srs_Emblem.svg" alt="SRS Emblem"/>
    </div>
    <div className="Phone_Home_our_customer">
      <p>Our Customer reviews</p>
      <Carousel  
        interval={3000} 
        showThumbs={false} 
        showArrows={false} 
        showStatus={false}
        >
          <img style={{backgroundColor:'white',marginBottom:'8vw'}} src="./icons/Phone_Home_srs_review_1.svg" alt="Customer Reviews"/>
          <img  style={{backgroundColor:'white',marginBottom:'8vw'}} src="./icons/Phone_Home_srs_review_2.svg" alt="Customer Reviews"/>
          <img style={{marginBottom:'8vw'}} src="./icons/Phone_Home_srs_review_3.svg" alt="Customer Reviews"/>
          <img  style={{marginBottom:'8vw'}} src="./icons/Phone_Home_srs_review_4.svg" alt="Customer Reviews"/>
        </Carousel>
      
    </div>
    <div className="Phone_get_quotation">
      <p className="Phone_get_quotation_heading">‘Turn your worn-out tire into new’</p>
      <div className="Phone_get_quotation_button">
        <p onClick={()=>
            {
                navigation("/Contact-us")
            }
        }>Get Quotation</p>
      </div>
    </div>
    <PhoneFooter/>
           </div>

    </div>
  )
}

export default Home