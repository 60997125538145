import React, { useState } from 'react'

import Navbar from './navbar'
import Footer from './Footer'
import FilterProduct from './FilterProduct'
import { useLocation } from 'react-router-dom'
import Modal from 'react-modal';
import PhoneProducts from './PhoneProducts'

function Products() {
  const location=useLocation()
  const location_final=location.state!=null?location:{
    state:{
      Vehicle_Type:"",
      Rim_size:"",
      Tyre_width:""
    }
  }
  const [modalIsOpen, setModalIsOpen] = useState(false);
  console.log(location)
  return (
    <div>
      <div className='laptop_products'>
      <Navbar/>
      <div className="prod-banner">
        <img src="./icons/ourProd-banner.svg" alt="banner" width="100%" height="350.625px" />
        <h2>Have your vehicle Tyre’d!</h2>
      </div>
      <FilterProduct Filters_value={location_final.state}/>
      <Footer/>
      </div>
      <div className='Phone_products'>
        <PhoneProducts/>
      </div>
  </div>
  )
}

export default Products