import React from 'react'
import Navbar from './navbar'
import Footer from './Footer'

import { Carousel } from 'react-responsive-carousel';
import NavBarMobile from './NavBarMobile';
import PhoneFooter from './PhoneFooter';

function aboutus() {
  return (
    <div>
        <div className='laptop_About_us'>
        <Navbar/>
        <div className="about_us_banner">
            <p>About Us</p>
        </div>
        <p className="about_content">
            Welcome to SRS Tyres, where we have proudly served our customers with top-quality tyre retreading services for over 27 years. As a leader in the industry, we are dedicated to delivering exceptional products and unparalleled customer satisfaction. Our commitment to excellence has made us a trusted name in tyre retreading, offering reliable and environmentally friendly solutions. At SRS Tyres, we take pride in our rich history of craftsmanship, ensuring that every retreaded tyre meets the highest standards of quality and safety. Join us on our journey as we continue to provide superior service and drive towards a greener future.
        </p>
        <p className="S_R_S_define">S.R.S defines</p>
        <div className="srs_features">
            <div className="container">
                <div className="features">
                    <div className="feature">
                        <img src="./icons/High_quality.svg" alt="High Quality" />
                    </div>
                    <div className="feature">
                        <img src="./icons/affordable.svg" alt="Affordable" />
                    </div>
                    <div className="feature">
                        <img src="./icons/customer_statisfaction.svg" alt="Customer Satisfaction" />
                    </div>
                    <div className="feature">
                        <img src="./icons/environmental.svg" alt="Sustainability" />
                    </div>
                </div>
                <div className="description">
                    <p>We specialize in providing high-quality retreaded tyres. Our mission is to offer environmentally sustainable tyre solutions while ensuring that everyone, regardless of their financial situation, has access to reliable and affordable tyres. By retreading worn-out tyres, we extend their lifespan and reduce waste, contributing to a greener future. We cater to a wide range of customers, from large landowners to small farmers, ensuring that all can experience the benefits of high-quality tyres without breaking the bank. Our dedication to quality and customer satisfaction has made us a trusted name in the industry for over 27 years.</p>
                </div>
            </div>
        </div>
        <img className="about_us_tyre_banner" src="./icons/about_us_tyre_banner.svg" alt="About Us Tyre Banner" />
        <div className="we_are_driven_by_container we_are_driven_by">
            <h1 className="we_are_driven_by_title">We are driven by</h1>
            <div className="we_are_driven_by_values">
                <div className="we_are_driven_by_value">
                    <h2 className="we_are_driven_by_value_title">Empowerment</h2>
                    <p className="we_are_driven_by_value_text">We believe that everyone should have access to quality tyres, regardless of their financial situation. Our retreaded tyres offer the performance and durability of new tyres at a more affordable price. This allows even small farmers to equip their tractors with dependable tyres, enabling them to work their fields with confidence. We are dedicated to ensuring that tyres are never a barrier to success, empowering all farmers, from the large landowner to the smallholder, to feel like the kings of their own land. At SRS Tyres, we are proud to make a positive impact on both the environment and the lives of our customers.</p>
                </div>
                <div className="we_are_driven_by_value we_are_driven_by_value_2">
                    <h2 className="we_are_driven_by_value_title">Commitment</h2>
                    <p className="we_are_driven_by_value_text">Our core mission focuses on two key principles: contributing to the environment and serving the people around us. We are deeply committed to environmental sustainability, and our tyre retreading process significantly reduces waste and conserves resources.</p>
                </div>
            </div>
        </div>
        <img src="./icons/our_journey.svg" className="our_journey" alt="Our Journey" />
        <div>
        <div className="Hands_of_srs">
            <p className="Hands_of_srs_heading">Hands of S.R.S</p>
            <p className="Hands_of_srs_content">We at SRS Tyres are truly blessed to have an exceptional team that is the backbone of our success. Our team members love working here, thanks to the positive culture and supportive environment we've cultivated. Their hard work and dedication are key to our achievements, and with their continued efforts, we are confident that SRS Tyres will reach even greater heights.</p>
        </div>
        </div>
        <div className="our_policy">
            <p className="our_policy_heading">Our Policy</p>
            <ul className="our_policy_content">
                <li>We provide high-quality retreaded tyres that meet or exceed industry standards and continuously improve through customer feedback and process enhancement.</li>
                <li>We implement eco-friendly practices, minimize waste through recycling, and adhere to environmental regulations.</li>
                <li>We ensure a safe working environment with regular training and clear emergency procedures for all employees.</li>
                <li>We foster a supportive and inclusive workplace, promoting professional development and work-life balance.</li>
                <li>We strive to exceed customer expectations with prompt, transparent, and effective service.</li>
                <li>We actively engage with and support local communities through charitable initiatives and environmental education.</li>
                <li>We conduct business with honesty and integrity, treating stakeholders with respect and adhering to legal standards.</li>
                <li>We foster a culture of creativity, integrate advanced technologies, and invest in research and development.</li>
                <li>We ensure suppliers adhere to ethical practices, prioritizing sustainability and quality partnerships.</li>
            </ul>
        </div>
        <div className="our_cutomer_say">
            <p className="our_cutomer_say_heading">Our customer’s about S.R.S Tyres</p>
            <div className="our_cutomer_say_row">
                <div className="our_cutomer_say_column">
                    <p className="our_cutomer_say_column_content">I recently purchased retreaded tyres from SRS Tyres, and I must say, the quality is outstanding. I was initially hesitant about switching to retreaded tyres, but the team at SRS made the entire process seamless and reassuring. They explained every detail, answered all my questions, and even offered a follow-up service to ensure everything was perfect. The tyres have performed exceptionally well, and I’ve experienced a noticeable improvement in my vehicle's handling. It’s refreshing to see a company so committed to quality and customer satisfaction. I highly recommend SRS Tyres for anyone looking for reliable and eco-friendly tyre solutions.</p>
                    <p className="our_cutomer_say_name">-Dharani Babu, Farmer</p>
                    <p className="our_cutomer_say_name" style={{ marginBottom: '10px' }}>Pondicherry</p>
                </div>
                <div className="our_cutomer_say_column">
                    <p className="our_cutomer_say_column_content">SRS Tyres truly stands out for its excellent customer service. From the moment I contacted them, I felt valued and well-informed. Their staff is incredibly knowledgeable and patient, taking the time to understand my needs and recommend the best options for my vehicle. The installation was quick, and they even provided tips on maintaining the tyres for optimal performance. What impressed me the most was their commitment to sustainability, which aligns perfectly with my values. SRS Tyres has earned my trust, and I am confident in their products and services. It’s not just about tyres; it’s about a company that genuinely cares for its customers.</p>
                    <p className="our_cutomer_say_name">-Pandur Sivalingam</p>
                    <p className="our_cutomer_say_name" style={{ marginBottom: '10px' }}>Kallakurichi, Tamil Nadu</p>
                </div>
                <div className="our_cutomer_say_column">
                    <p className="our_cutomer_say_column_content">I’ve been a loyal customer of SRS Tyres for over five years, and their consistency in delivering top-notch service keeps me coming back. The trustworthiness of their team and the transparency in their dealings is commendable. Whenever I visit, I am greeted warmly and treated with utmost respect. They have a way of making every customer feel important, which is rare in today’s fast-paced world. SRS Tyres not only offers high-quality products but also provides a level of service that makes you feel part of their community. Their dedication to customer satisfaction is truly impressive, and I highly recommend them to anyone in need of reliable tyre solutions.</p>
                    <p className="our_cutomer_say_name">-MKS Reddiyar</p>
                    <p className="our_cutomer_say_name" style={{ marginBottom: '10px' }}>Villupuram, Tamil Nadu</p>
                </div>
            </div>
            <p className="our_cutomer_say_and_more">And Counting more</p>
        </div>
        <div className="heart_of_srs">
            <p className="heart_of_srs_heading">From the heart of SRS Tyres</p>
            <img className="heart_of_srs_line" src="./icons/line_from_heart_of_srs.svg" alt="Line from Heart of SRS" />
            <p className="heart_of_srs_content">As the owner of SRS Tyres, I feel a deep sense of pride and responsibility in what we do. For over 27 years, we've been dedicated to providing high-quality retreaded tyres that our customers can rely on. Our work isn't just about tyres; it's about making a positive impact on both people and the environment. We believe in offering affordable solutions without compromising on quality, ensuring that everyone—from large landowners to small farmers—can have access to dependable tyres. It's incredibly fulfilling to know that we're helping reduce waste and empowering farmers to work their fields with confidence. At SRS Tyres, we're committed to making a difference in every tyre we retread and every customer we serve.</p>
            <p className="heart_of_srs_name">-Sujatha</p>
            <p className="heart_of_srs_owner">Owner, S.R.S Tyres</p>
        </div>
        <div className="tryes_you_can">
            <p>Tyres You Can Trust, Fields You Can Rely On.</p>
        </div>
        <Footer/>
        </div>
        <div className="Phone_About_us">
          <NavBarMobile/>
      <div className="Phone_About_us_banner">
        <p>About Us</p>
      </div>
      <p className="Phone_About_us_content">
        Welcome to SRS Tyres, where we have proudly served our customers with top-quality tyre retreading services for over 27 years. As a leader in the industry, we are dedicated to delivering exceptional products and unparalleled customer satisfaction. Join us on our journey as we continue to provide superior service and drive towards a greener future.
      </p>
      <p className="Phone_About_us_srs_define">S.R.S defines</p>
      <div className="Phone_About_us_srs_define_content">
        <div className="Phone_About_us_srs_define_content_high_quality">
          <img src="./icons/High_quality.svg" alt="High Quality"/>
        </div>
        <div className="Phone_About_us_srs_define_content_high_quality">
          <img src="./icons/affordable.svg" alt="Affordable"/>
        </div>
        <div className="Phone_About_us_srs_define_content_high_quality">
          <img src="./icons/customer_statisfaction.svg" alt="Customer Satisfaction"/>
        </div>
        <div className="Phone_About_us_srs_define_content_high_quality">
          <img src="./icons/environmental.svg" alt="Environmental"/>
        </div>
      </div>
      <img className="Phone_About_tyre_banner" src="./icons/about_us_tyre_banner.svg" alt="Tyre Banner"/>
      <div className="we_are_driven_by">
        <p className="we_are_driven_by_heading">We are driven by</p>
        <div className="we_are_driven_by_empowerment">
          <p className="we_are_driven_by_empowerment_heading">Empowerment</p>
          <p className="we_are_driven_by_empowerment_content">
            Our retreaded tyres offer the performance and reliability of new tyres at a more affordable price. This allows even small farmers to equip their tractors with dependable tyres, enabling them to work their fields with confidence. We are dedicated to ensuring that tyres are never a barrier to success, empowering all farmers, from the large landowner to the smallholder, to feel like the kings of their own land.
          </p>
        </div>
        <div className="we_are_driven_by_empowerment">
          <p className="we_are_driven_by_empowerment_heading">Commitment</p>
          <p className="we_are_driven_by_empowerment_content">
            Our core mission focuses on two key principles: contributing to the environment and serving the people around us. We are deeply committed to environmental sustainability, and our tyre retreading process significantly reduces waste and conserves resources.
          </p>
        </div>
      </div>
      <div className="Phone_our_about_us_our_journey">
        <img src="./icons/Phone_about_us_our_journey.svg" alt="Our Journey"/>
      </div>
      <div className="Phone_Hands_of_srs">
        <p className="Phone_Hands_of_srs_heading">Hands of S.R.S</p>
        <p className="Phone_Hands_of_srs_content">
          We at SRS Tyres are truly blessed to have an exceptional team that is the backbone of our success. Our team members love working here, thanks to the positive culture and supportive environment we've cultivated. Their hard work and dedication are key to our achievements, and with their continued efforts, we are confident that SRS Tyres will reach even greater heights.
        </p>
      </div>
      <div className="Phone_our_policy">
        <p className="Phone_our_policy_heading">Our Policy</p>
        <ul className="Phone_our_policy_content">
          <li>We provide high-quality retreaded tyres that meet or exceed industry standards and continuously improve through customer feedback and process enhancement.</li>
          <li>We implement eco-friendly practices, minimize waste through recycling, and adhere to environmental regulations.</li>
          <li>We ensure a safe working environment with regular training and clear emergency procedures for all employees.</li>
          <li>We foster a supportive and inclusive workplace, promoting professional development and work-life balance.</li>
          <li>We strive to exceed customer expectations with prompt, transparent, and effective service.</li>
          <li>We actively engage with and support local communities through charitable initiatives and environmental education.</li>
          <li>We conduct business with honesty and integrity, treating stakeholders with respect and adhering to legal standards.</li>
          <li>We foster a culture of creativity, integrate advanced technologies, and invest in research and development.</li>
          <li>We ensure suppliers adhere to ethical practices, prioritizing sustainability and quality partnerships.</li>
        </ul>
      </div>
      <div className="Phone_our_cutomer_say">
        <p className="Phone_our_cutomer_say_heading">Our customer’s about S.R.S Tyres</p>
        <Carousel  
        interval={3000} 
        showThumbs={false} 
        showArrows={false} 
        showStatus={false}  >
          <div className="Phone_our_cutomer_say_row">
          <div className="Phone_our_cutomer_say_column">
            <p className="Phone_our_cutomer_say_column_content">
              I recently purchased retreaded tyres from SRS Tyres, and I must say, the quality is outstanding. I was initially hesitant about switching to retreaded tyres, but the team at SRS made the entire process seamless and reassuring. They explained every detail, answered all my questions, and even offered a follow-up service to ensure everything was perfect. The tyres have performed exceptionally well, and I’ve experienced a noticeable improvement in my vehicle's handling. It’s refreshing to see a company so committed to quality and customer satisfaction. I highly recommend SRS Tyres for anyone looking for reliable and eco-friendly tyre solutions.
            </p>
            <p className="Phone_our_cutomer_say_name">-Dharani Babu, Farmer</p>
            <p className="Phone_our_cutomer_say_name">Pondicherry</p>
          </div>
        </div>
        <div className="Phone_our_cutomer_say_row">
          <div className="Phone_our_cutomer_say_column">
            <p className="Phone_our_cutomer_say_column_content">
            SRS Tyres truly stands out for its excellent customer service. From the moment I contacted them, I felt valued and well-informed. Their staff is incredibly knowledgeable and patient, taking the time to understand my needs and recommend the best options for my vehicle. The installation was quick, and they even provided tips on maintaining the tyres for optimal performance. What impressed me the most was their commitment to sustainability, which aligns perfectly with my values. SRS Tyres has earned my trust, and I am confident in their products and services. It’s not just about tyres; it’s about a company that genuinely cares for its customers.
            </p>
            <p className="Phone_our_cutomer_say_name">-Pandur Sivalingam</p>
            <p className="Phone_our_cutomer_say_name">Kallakurichi, Tamil Nadu</p>
          </div>
        </div>
        <div className="Phone_our_cutomer_say_row">
          <div className="Phone_our_cutomer_say_column">
            <p className="Phone_our_cutomer_say_column_content">
            I’ve been a loyal customer of SRS Tyres for over five years, and their consistency in delivering top-notch service keeps me coming back. The trustworthiness of their team and the transparency in their dealings is commendable. Whenever I visit, I am greeted warmly and treated with utmost respect. They have a way of making every customer feel important, which is rare in today’s fast-paced world. SRS Tyres not only offers high-quality products but also provides a level of service that makes you feel part of their community. Their dedication to customer satisfaction is truly impressive, and I highly recommend them to anyone in need of reliable tyre solutions.
            </p>
            <p className="Phone_our_cutomer_say_name">-MKS Reddiyar</p>
            <p className="Phone_our_cutomer_say_name">Villupuram, Tamil Nadu</p>
          </div>
        </div>
          </Carousel>
      </div>
      <div className="Phone_heart_of_srs">
        <p className="Phone_heart_of_srs_heading">From the heart of SRS Tyres</p>
        <img className="Phone_heart_of_srs_line" src="./icons/line_from_heart_of_srs.svg" alt="Line from Heart of SRS"/>
        <p className="Phone_heart_of_srs_content">
          As the owner of SRS Tyres, I feel a deep sense of pride and responsibility in what we do. For over 27 years, we've been dedicated to providing high-quality retreaded tyres that our customers can rely on. Our work isn't just about tyres; it's about making a positive impact on both people and the environment. We believe in offering affordable solutions without compromising on quality, ensuring that everyone—from large landowners to small farmers—can have access to dependable tyres. It's incredibly fulfilling to know that we're helping reduce waste and empowering farmers to work their fields with confidence. At SRS Tyres, we're committed to making a difference in every tyre we retread and every customer we serve.
        </p>
        <p className="Phone_heart_of_srs_name">-Sujatha</p>
        <p className="Phone_heart_of_srs_owner">Owner, S.R.S Tyres</p>
      </div>
      <div className="Phone_tryes_you_can">
        <p>Tyres You Can Trust, Fields You Can Rely On.</p>
      </div>
      <PhoneFooter/>
    </div>
    </div>
  )
}

export default aboutus