import React from 'react';
import { useState,useEffect } from 'react';
import Database from "./DataBase.json"
import { Carousel } from 'react-responsive-carousel';

const PhoneProductsPopup = (props) => {
    const [activeAccordian,setactiveAccordian]=useState(null)
    const [currentImage,setcurrentImage]=useState({
        mainImage:"../icons/ourProd-tyre-img.svg",
        subImage1:"../icons/Products_tyre_image2.jpeg",
        subImage2:"../icons/Products_tyre_image3.jpeg"
    })
    const handleactiveAccordian=(value)=>
    {
        console.log("activeAccordian",activeAccordian)
        console.log("value",value)
        if (value==activeAccordian)
            {
                console.log("came inside")
                setactiveAccordian(null)
            }
            else if(value=="Details" )
        {
            setactiveAccordian("Details")
        }
        else if(value=="More")
        {
            setactiveAccordian("More")
        }else if (value=="Features")
        {
            setactiveAccordian("Features")
        }
    }
    const [ProductDetails,setProductDetails]=useState({
        title:"",
        description:"",
        Vehicle_Type:"",
        size:"",
        usedIn:"",
        Rating:"",
        Load_Capacity:"",
        featuresJSX:"",
        MoreJSX:""

    })
    useEffect(()=>
    {
        for(let i=0;i<Database.Products.length;i++)
            {
                if(Database.Products[i].Code==props.productID)
                {
                    setProductDetails({
                        title:Database.Products[i].Size+" - "+Database.Products[i].Vehicle_Type+" Tyre",
                        description:Database.Products[i].Description,
                        Vehicle_Type:Database.Products[i].Vehicle_Type,
                        size:Database.Products[i].Size,
                        usedIn:Database.Products[i].Used_In,
                        Rating:Database.Products[i].Rating,
                        Load_Capacity:Database.Products[i].Load_Capacity,
                        featuresJSX:Database.Products[i].Features.slice(0,4).map((item,i)=><li key={i}>{item}</li>),
                        MoreJSX:Database.Products[i].More.map((item,i)=><li key={i}>{item}</li>)
                    })
                }
            }
    },[])
    const handleClickcall = () => {
        window.location.href = 'tel:+1234567890'; // Replace with your phone number
      };
  return (
    <div className="prod-mobile-cont-mobile">
      <div className="prod_acc_top">
        <p className='Phone_PopUp_close' onClick={()=>props.modalOpen(false)}>X</p>
        <p>6.00-16 - Industrial Tyre</p>
        <span className="arrow left-arrow">&#10094;</span>
        <div className='Phone_popup_slider'>
        <Carousel  
        interval={3000} 
        showThumbs={false} 
        showArrows={false} 
        showStatus={false}
        >
           <img className="Phone_popup_slider_image" src={currentImage.mainImage} alt="accordion"/>
           <img className="Phone_popup_slider_image" src={currentImage.subImage2} alt="accordion"/>
           <img className="Phone_popup_slider_image" src={currentImage.subImage1} alt="accordion"/>
        </Carousel>
            
        </div>
        <span className="arrow right-arrow">&#10095;</span>
        <div className="prod-left-down-mobile">
          <img src={currentImage.subImage1} onClick={()=>
                        {
                            setcurrentImage({
                                ...currentImage,
                                mainImage:currentImage.subImage1,
                                subImage1:currentImage.mainImage
                            })
                        }
                    }  alt="" className="img-small" />
          <img src={currentImage.subImage2} onClick={()=>
                        {
                            setcurrentImage({
                                ...currentImage,
                                mainImage:currentImage.subImage2,
                                subImage2:currentImage.mainImage
                            })
                        }
                    }  alt="" className="img-small" style={{marginLeft:'1vw'}} />
        </div>
        <div className="prod-phone-icon">
          <img src="../icons/our_prod_acc_phone_icon.svg" alt="phone" className="phone-img" />
        </div>
      </div>

      <div className="prod_acc_down">
        <div className="prod-right-mobile">
          <p>{ProductDetails.title}</p>
          <h6>{ProductDetails.description}</h6>
          <div className="prod-spacing-mobile">
            <div onClick={()=>
                        {
                            handleactiveAccordian("Details")
                        }
                    } className={activeAccordian=="Details"?"prod-mobile-cont active":"prod-mobile-cont"} >
              <div className="label">Details</div>
              <div className="cont">
                <ul>
                  <li><span>Size:</span>{ProductDetails.size} <span style={{marginLeft:'0.5vw'}}>Vehicle Type:</span>{ProductDetails.Vehicle_Type}</li>
                  <li><span>Used In:</span>{ProductDetails.usedIn}</li>
                  <li><span>Load Capacity:</span> {ProductDetails.Load_Capacity} <span>Rating:</span> {ProductDetails.Rating}</li>
                  {/* <li><span>Rating:</span> 80 km/h</li>
                  <li><span>Lug Count:</span> 20</li> */}
                </ul>
              </div>
            </div>

            <div onClick={()=>
                        {
                            handleactiveAccordian("Features")
                        }
                    } className={activeAccordian=="Features"?"prod-mobile-cont active":"prod-mobile-cont"} >
              <div className="label">Features</div>
              <div className="cont">
                <ul>
                {ProductDetails.featuresJSX}
                </ul>
              </div>
            </div>

            <div onClick={()=>
                        {
                            handleactiveAccordian("More")
                        }
                    } className={activeAccordian=="More"?"prod-mobile-cont active":"prod-mobile-cont"}>
              <div className="label">More</div>
              <div className="cont">
                <ul>
                {ProductDetails.MoreJSX}
                </ul>
              </div>
            </div>
          </div>
          <div className="prod-acc-btn">
            <button onClick={handleClickcall} type="button">Call now</button>
          </div>
          <p className="p-2">Back</p>
        </div>
      </div>
    </div>
  );
};

export default PhoneProductsPopup;
