import AboutUs from "./components/aboutus";
import Ourservice from "./components/ourservice";
import ContactUSPage from "./components/ContactUSPage";
import Home from "./components/Home";
import { BrowserRouter,Routes,Route } from "react-router-dom";
import React from "react";
import Products from "./components/Products";

function App() {
  return (
    <div>
      <BrowserRouter>
      <Routes>
        <Route path="/" Component={Home}/>
        <Route path="/About-us" Component={AboutUs}/>
        <Route path="/Our-service" Component={Ourservice}/>
        <Route path="/Contact-us" Component={ContactUSPage}/>
        <Route path="/Products" Component={Products}/>
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
