import React from 'react'
import { useState } from 'react'
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "./firebase"; 
import emailjs from '@emailjs/browser'
import District from "./Districts.json"

function ContactUSRetreadingFromPage() {
    const [formDetails, setFormDetails] = useState({
        Name: "",
        phoneNumber: "",
        Email: "",
        State: "",
        District: "",
        TyreSize: "",
        VehicleNameModel: "",
        Message: "",
        Pincode:""
      });
      
      const [emptyForm, setemptyForm] = useState(false);
      const [Districtsselect,setDistrictsselect]=useState([])
      const [imageUrl, setImageUrl] = useState("");
      const[loaderstatus,setloadserstatus]=useState(0)

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const storageRef = ref(storage, `images/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Optional: Track upload progress here
        setloadserstatus(()=>1)
      },
      (error) => {
        console.error("Upload failed:", error);
      },
      () => {
        // Get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setImageUrl(url);
          console.log("File available at", url);
          setloadserstatus(()=>0)
        });
      }
    );
  };
      const onHandleFormData = (event) => {
        const { name, value } = event.target;
        setFormDetails({
          ...formDetails,
          [name]: value
        });
        if(name=="State"){
          const districtSelect=District.states.filter((stateObject)=>stateObject.state===value && value!="")
          setDistrictsselect(districtSelect[0].districts)
      }
        if (emptyForm === true) {
          setemptyForm(false);
        }
      };
    
      const sendEmail = () => {
        const templateParams = {
          from_name: formDetails.Name,
          from_phone: formDetails.phoneNumber,
          from_email: formDetails.Email,
          your_state: formDetails.State,
          your_district: formDetails.District,
          tyre_size: formDetails.TyreSize,
          vehicle_name_model: formDetails.VehicleNameModel,
          your_pincode:formDetails.Pincode,
          message: formDetails.Message,
          to_email: 'jaideepln2002@gmail.com',
          image_url:imageUrl
        };
    
        emailjs.send('service_pp7v87q', 'template_jlzogb8', templateParams, 'BHNILRXTRGgN2So2x')
          .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            setFormDetails({
              Name: "",
              phoneNumber: "",
              Email: "",
              State: "",
              District: "",
              TyreSize: "",
              VehicleNameModel: "",
              Message: "",
              Pincode:""

            });
            alert('Message sent successfully!');
          }, (err) => {
            console.log('FAILED...', err);
            setFormDetails({
              Name: "",
              phoneNumber: "",
              Email: "",
              State: "",
              District: "",
              TyreSize: "",
              VehicleNameModel: "",
              Message: "",
              Pincode:""
            });
            alert('Failed to send the message. Please try again.');
          });
      };
      const stateSelect=District.states.map((item,i)=><option value={item.state} key={i} >{item.state}</option>)
      const districtSelectDropDown=Districtsselect.map((item,i)=><option value={item} key={i} >{item}</option>)
      const handleSendButton = () => {
        const { Name, phoneNumber, Email, State, District, Message ,Pincode} = formDetails;
        if (Name === "" || phoneNumber === "" || Email === "" || State === "" || District === "" || Message === ""||Pincode=="") {
          setemptyForm(true);
        } else {
          sendEmail();
        }
      };
    
      return (
        <div>
          {loaderstatus==0?null:
          <div  style={{position:'absolute',width:'79vw',zIndex:'2',backgroundColor:'transparent',height:'50vw'}}>
            <img src='./icons/loading_bar.gif' style={{width:'20vw',margin:'15vw 30vw',borderRadius:'50%'}}/>
          </div>}
         <div>
         <div className="input_row">
            <div className="input_element">
              <label>Full Name</label>
              {emptyForm && formDetails.Name === "" ? <p className='error_form'>Please provide your Full Name</p> : null}
              <input name='Name' value={formDetails.Name} className={emptyForm && formDetails.Name === "" ? "error_input" : ""} onChange={onHandleFormData} type="text" />
            </div>
            <div className="input_element_1">
              <label>Phone Number</label>
              {emptyForm && formDetails.phoneNumber === "" ? <p className='error_form'>Please provide your Phone Number</p> : null}
              <input name='phoneNumber' value={formDetails.phoneNumber} className={emptyForm && formDetails.phoneNumber === "" ? "error_input" : ""} onChange={onHandleFormData} type="number" />
            </div>
          </div>
          <div className="input_row" style={{display:'flex'}}>
        <div style={{display:'flex'}}>
            <div className="input_element_email">
                <label>Email</label>
                {emptyForm===true &&formDetails.Email===""?<p className='error_form'>Please provide your Email</p>:null}
                <input name='Email' value={formDetails.Email} className={emptyForm===true &&formDetails.Email===""?"error_input":""} onChange={onHandleFormData} type="email" />
            </div>
            <div className="input_element_state">
                <label>State</label>
                {emptyForm && formDetails.State === "" ? <p className='error_form'>Please provide your State</p> : null}
                <select name='State' value={formDetails.State} className={emptyForm===true &&formDetails.State===""?"error_input":""}  id="rim" onChange={onHandleFormData}>
                    <option value="" disabled selected>Select state</option>
                    {stateSelect}
                </select>
            </div>
        </div>
        <div style={{display:'flex',marginLeft:'6.2vw'}}>
            <div className="distrcit_input_element_1">
                <label>District</label>
                {emptyForm && formDetails.District === "" ? <p className='error_form'>Please provide your District</p> : null}
                <select name='District' value={formDetails.District} className={emptyForm===true &&formDetails.District===""?"error_input":""}  id="rim" onChange={onHandleFormData}>
                    <option value="" disabled selected>Select District</option>
                    {districtSelectDropDown}
                </select>
            </div>
            <div className="pincode_element_1">
                <label>Pincode</label>
                {emptyForm===true &&formDetails.Pincode===""?<p className='error_form'>Please provide your Pincode</p>:null}
                <input name='Pincode' value={formDetails.Pincode} className={emptyForm===true &&formDetails.Pincode===""?"error_input":""} onChange={onHandleFormData} type="number" />
            </div>
        </div>
    </div>
          <div className="input_row">
            <div className="input_element">
              <label>Vehicle Name & Model</label>
              {emptyForm && formDetails.District === "" ? <p className='error_form'>Please provide your Vehicle model</p> : null}
              <input name='VehicleNameModel' className={emptyForm && formDetails.VehicleNameModel === "" ? "error_input" : ""}  value={formDetails.VehicleNameModel} onChange={onHandleFormData} type="text" />
            </div>
            <div className="input_element_1">
              <label>Tyre Size</label>
              {emptyForm && formDetails.District === "" ? <p className='error_form'>Please provide your Tyre size</p> : null}
              <input name='TyreSize' className={emptyForm && formDetails.TyreSize === "" ? "error_input" : ""} value={formDetails.TyreSize} onChange={onHandleFormData} type="text" />
            </div>
          </div>
          <div className="input_row">
            <div className="input_element_3">
              <label>Write in detail about the service you want from us</label>
              {emptyForm && formDetails.Message === "" ? <p className='error_form'>Please provide your Message</p> : null}
              <textarea name='Message' value={formDetails.Message} className={emptyForm && formDetails.Message === "" ? "error_input" : ""} onChange={onHandleFormData} type="text" ></textarea>
            </div>
          </div>
          <div className='Input_row_upload_file'>
            <input onChange={handleImageUpload} type="file" />
            <img src="./icons/Phone_contact_us_retreading_file.svg" alt="File Upload Icon" />
          </div>
          <div className="input_row">
            <button onClick={handleSendButton}>Send</button>
          </div>
         </div>
        </div>
      );
}

export default ContactUSRetreadingFromPage