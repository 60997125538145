import React from 'react'
import Navbar from './navbar'
import { useNavigate } from 'react-router-dom'
import Footer from './Footer'
import { useRef } from 'react'
import PhoneOurservices from './PhoneOurservices'

function Ourservice() {
    const navigation=useNavigate()
    const retreadingsectionRef = useRef(null);
    const TyressectionRef = useRef(null);
    const handleRetreadingClick=()=>
    {
        if (retreadingsectionRef.current) {
            retreadingsectionRef.current.scrollIntoView({ behavior: 'smooth' });
          }
    }
    const handleOurTyreClick=()=>
        {
            if (TyressectionRef.current) {
                TyressectionRef.current.scrollIntoView({ behavior: 'smooth' });
              }
        }
  return (
    <div>
        <div className='laptop_our_services'>
        <Navbar/>
        <div>
            <div className='our_services_banner'>
                <p className='our_services_banner_heading'>Our Services </p>
                <div style={{display:'flex'}}>
                    <p onClick={handleRetreadingClick} className="our_services_banner_button">Retreading</p>
                    <p onClick={handleOurTyreClick} className='our_services_banner_button_2'>Our Tyres</p>
                </div>
            </div>
            <div style={{ marginTop: '68px' }} ref={retreadingsectionRef} >
                <p className="what_is_retreading" >What is Retreading?</p>
                <p className="what_is_retreading_content" >
                Retreading is a method of refurbishing a worn tire by removing the old tread and applying a new layer of rubber. This process allows the tire to be reused, extending its lifespan and providing a cost-effective solution compared to purchasing a new tire. Additionally, retreading contributes to environmental sustainability by reducing waste and conserving the resources needed to produce new tires.
                </p>
            </div>

            <div style={{ marginTop: '68px' }}>
                <p className="the_process">The process</p>
                <p className="the_process_content">
                At SRS Tyres, we take pride in our careful retreading process, ensuring that each tire meets the highest standards of quality and safety.
                </p>
                <img src="./icons/how_do_we.svg" style={{ marginTop: '20px', width: '100%' }} alt="How We Do It" />
            </div>

            <div className="retread_any_tyre">
                <p>
                At SRS Tyres, we blend advanced technology with skilled craftsmanship to deliver retreaded tires that are as good as new. Our process not only extends the life of tires but also helps protect the environment, making us a smart choice for customers and the planet. It’s your turn now!
                </p>
                <button onClick={()=>
                    {
                        navigation("/Contact-us")
                    }
                }>Retread my tire</button>
            </div>

            <div>
                <img src="./icons/line.svg" className="line_class" alt="Line" />
            </div>

            <p className="do_you_know">Do you know this?</p>

            <div className="the_lugs_difference" style={{ marginTop: '80px' }}>
                <img src="./icons/the_lugs_difference.svg" alt="The Lugs Difference" />
            </div>

            <p className="below_lugs_difference">
                At SRS Tyres, we've recognized the importance of proper lug design in enhancing tire performance. By providing our customers with tailored lug patterns, we've made a significant difference, offering them better traction and durability. This focus on quality has benefited our customers, helping them achieve superior results in challenging conditions.
            </p>

            <img className="line2" src="./icons/line2.svg" alt="Line 2" />

            <p className="our_tyre" ref={TyressectionRef}>Our Tyres</p>
            <p className="out_tyre_content">
                At SRS Tyres, we specialize in offering high-quality retreaded tires that match the performance of new tires. We purchase worn-out tires from our customers, expertly retread them, and sell them in our exclusive store. Our customers trust and appreciate the quality and reliability of our products. By focusing solely on retreaded tires, we not only provide a cost-effective solution but also contribute to a greener environment. Our commitment to quality and sustainability ensures satisfied customers and a positive impact on the planet.
            </p>

            <div className="the_greener_way">
                <p className="the_greener_way_heading">The greener way</p>
                <p className="the_greener_way_heading_content">
                As a tire retreading company, we are committed to providing an environmentally friendly solution that reduces waste and conserves resources. By extending the life of tires, we minimize the need for new materials and decrease carbon emissions. Our skilled professionals, along with customers who choose retreaded tires, play a crucial role in supporting a more sustainable future. We appreciate their efforts in helping us protect the planet and promote responsible consumption.
                </p>
                <img className="the_greener_way_line3" src="./icons/line3.svg" alt="Line 3" />

                <div className="contribute_and_click_here">
                <p>Contribute to nature, sell us your used tires</p>
                <button onClick={()=>
                    {
                        navigation("/Contact-us")
                    }
                }>Click here</button>
                </div>

                <img className="lin_4_contribute" src="./icons/line3.svg" alt="Line 4" />
            </div>
        </div>
        <Footer/>
        </div>
        <PhoneOurservices handleRtreadingClick={handleRetreadingClick} HandleOurTyre={handleOurTyreClick}/>
    </div>
  )
}

export default Ourservice